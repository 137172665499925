html {
  position: relative;
  height: 100%;
}

body {
  font-family: 'Josefin Sans', sans-serif;
  height: 100%;
}

*:focus {
  outline: none;
}

#root {
  position: relative;
  min-height: 100vh;
  padding-bottom: 3rem;
  background-color: #FFFAEB;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: "calc(height)";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.genderPick {
  color: #402F11  !important;
  background-color: Transparent !important;
  border: 0px;
  box-shadow: none;
}

.genderPick.focus {
  color: #402F11 !important;
  background-color: Transparent !important;
  border: 0px;
  box-shadow: none;
  border-bottom: 2px solid black;
}

.genderPickSelected {
  color: #402F11 !important;
  background-color: Transparent !important;
  border: 0px;
  box-shadow: none;
  border-bottom: 2px solid black;
}

.sellerPick {
  color: #402F11  !important;
  background-color: Transparent !important;
  border: 0px;
  box-shadow: none;
}

.sellerPick.focus {
  color: #402F11 !important;
  background-color: Transparent !important;
  border: 0px;
  box-shadow: none;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none!important;
}

.image-upload>input {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.inputLabel {
  color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input.loginInput::placeholder {
  color: black;
  opacity: 1;
}

.emojiPicker {
  cursor: pointer;
}

.connectionMessage {
  text-align: center;
  position: fixed;
  padding: 0.2rem;
  visibility: visible;
  border-radius: 1.5rem;
  width: 45%;
  top: 2rem; 
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
  font-size: 0.7rem;
  pointer-events: none;
}

.offlinePrompt {
  background-color: rgb(216, 83, 83);
  opacity: 0.75;
  transform: translateY(100%);
  transition: all .3s ease-out;
}

.onlinePrompt {
  background-color: rgb(50, 219, 149);
  opacity: 0 100ms;
  transform: translateY(100%);
  transition: all .3s ease-out;
  animation: fade 3s;
  -webkit-animation-fill-mode: both;
}

@keyframes fade {
  0% {
      opacity: 1;
  }
  100% {
     opacity: 0;
  }
}

.carousel-outer .carousel .slide{ 
	background: "red"; 
}

.progress-bar {
  background: linear-gradient(
    180deg,
    rgba(186, 220, 88, 1) 6%,
    rgba(54, 126, 66, 1) 100%
  );
}

.carousel .slide iframe {
  margin: 0 0 40px 0 !important;
}

.popup-content {
  margin: auto;
  width: 40%;
  background: rgb(255, 255, 255);
  padding: 25px;
  text-align: center;
  border: 2px solid #FFC82F;
  border-radius: 5px;
}

@media only screen and (max-width : 550px) {
  .popup-content {
    width: 90%
  }
}

.popup-content a {
  color: #979797;
  text-decoration: underline;
}
.popup-content button {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 5px 10px;
  margin: 15px;
  color: white;
  border: none;
}
.popup-content button.negative {
  color: black;
  border: 1px solid #6BA846;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: white;
}
.popup-content button.positive {
  background: linear-gradient(343.6deg, #6BA846 9.15%, #BADC58 143.44%);
}
.popup-content button.delete {
  background: linear-gradient(343.6deg, #6e1414 9.15%, #ff0707 143.44%);
}
.popup-content h3 {
  font-weight: bold;
  margin-bottom: 1rem;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 20px;
  height: 26px;
  left: 50px;
  top: 20px;
}

.bm-burger-button-desktop {
  position: relative;
  width: 20px;
  height: 26px;
  left: 0;
  top: 0;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 200px !important;
}

/* General sidebar styles */
.bm-menu {
  background: #1c3d08;
  padding: 2.5em 1em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #6ba846 !important;
  text-decoration: none !important;
  margin: 0.5rem 0;
  }

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Menu icons */
.menu-icon {
  margin-right: 0.5rem;
}